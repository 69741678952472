<template>
  <Layout tituloPagina="Sistema | Cuenta | Modulo soporte">
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              Selección de plan
            </h4>
            <div>
              <div class="form-check form-switch form-switch-lg" dir="ltr">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="moduloSoporte"
                  :checked="modulo"
                  @change="modulo = !modulo"
                />
                <label class="form-check-label" for="moduloSoporte">
                  <!-- {{modulo ? 'Modulo activo' : 'Modulo desactivado'}} -->
                </label>
              </div>
            </div>
          </div>
          <div class="card-body" style="min-height: 475px;">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" style="padding-right: 0px;">
                <div class="card">
                  <div class="card-header bg-soft-dark">
                    <h4 class="card-title">Caracteristicas</h4>
                  </div>
                  <div class="card-body">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td style="height: 180px;">
                            Descripción
                          </td>
                        </tr>
                        <tr>
                          <td style="height: 280px;">
                            Temas que se cubren
                          </td>
                        </tr>
                        <tr>
                          <td style="height: 180px;">Precio</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" style="padding-right: 0px; padding-left: 0px;">
                <div
                  class="card"
                  :class="plan_seleccionado == 1 ? 'border border-success': ''"
                  style="cursor: pointer;"
                  @click="seleccionarPlan(1)"
                >
                  <div class="card-header" :class="plan_seleccionado == 1 ? 'bg-success text-white': 'bg-soft-dark'">
                    <h4 class="card-title">Soporte Básico</h4>
                  </div>
                  <div class="card-body">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td style="height: 180px;">
                            Soporte para atender dudas y ayudar a los usuarios del sistema 
                            ArgusBlack en la implementación del sistema en sus redes.
                          </td>
                        </tr>
                        <tr>
                          <td style="height: 280px;">
                            <ul>
                              <li type="1">Resolución de dudas sobre el sistema ArgusBlack</li>
                              <li type="1">
                                Ayuda para enlazar el router al sistema.
                                <ul style="list-style-type:disc">
                                  <li type="a">
                                    Vía Messenger, Whatsapp o telefónica.
                                  </li>
                                  <li type="a">
                                    Remota por AnyDesk.
                                  </li>
                                </ul>
                              </li>
                              <li type="1">Información sobre routers Mikrotik.</li>
                              <li type="1">Ayuda en la selección del router Mikrotik adecuado para sus redes.</li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td style="height: 180px;">$0.00 USD por hora</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" style="padding-left: 0px;">
                <div
                  class="card"
                  :class="plan_seleccionado == 2 ? 'border border-success': ''"
                  style="cursor: pointer;"
                  @click="seleccionarPlan(2)"
                >
                  <div class="card-header justify-content-between d-flex align-items-center"
                    :class="plan_seleccionado == 2 ? 'bg-success text-white': 'bg-soft-dark'"
                  >
                    <h4 class="card-title">Soporte Avanzado</h4>
                    <div>
                      <span class="badge bg-info" title="horas disponibles">
                        0 hrs
                      </span>
                    </div>
                  </div>
                  <div class="card-body">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td style="height: 180px;">
                            Soporte para implementar configuraciones, creación de scripts para Mikrotik, 
                            revisión de configuraciones de antenas, routers, switch o cualquier tema relacionado 
                            con la configuración que soliciten los administradores de red.
                          </td>
                        </tr>
                        <tr>
                          <td style="height: 280px;">
                            <ul>
                              <li type="1">Soporte vía remota por AnyDesk, telefónica, Messenger o Whatsapp.</li>
                              <li type="1">
                                Para Mikrotik
                                <ul style="list-style-type:disc">
                                  <li type="a">
                                    Revisión y análisis de configuraciones.
                                  </li>
                                  <li type="a">
                                    Diseño e implementación de configuraciones.
                                  </li>
                                  <li type="a">
                                    Creación de scripts.
                                  </li>
                                </ul>
                              </li>
                              <li type="1">Configuración de antenas Ubiquiti.</li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td style="height: 180px;">
                            $20.00 USD por hora<br>
                            $90.00 USD por bolsa de 5 horas ( incluye 10% de descuento )<br>
                            $180.00 USD por bolsa de 10 horas ( incluye 10% de descuento )<br>
                            $340.00 USD por bolsa de 20 horas ( incluye 15% de descuento )
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center bg-primary">
            <h4 class="card-title text-white">Pago de módulo Soporte</h4>
            <div>
              <i class="bx bxl-paypal h1 mb-0 text-white"></i>
            </div>
          </div>
          <div class="card-body">
            <h2>Soporte Avanzado</h2>
            <hr style="margin-top: 1rem; margin-bottom: 1rem;">
            
            <table class="table" v-show="plan_seleccionado == 2">
              <thead>
                <tr>
                  <th style="width:33.33333%">Bolsa de horas</th>
                  <th style="width:33.33333%">Cantidad</th>
                  <th style="width:33.33333%" class="text-center">Subtotal</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    1 hola
                  </td>
                  <td>
                    <div class="input-group">
                      <button class="btn btn-soft-dark" @click="sumarBolsas(1)">
                        <i class="fa fa-plus"></i>
                      </button>
                      <input class="form-control text-center" readonly v-model="cantidad_una_hora">
                      <button class="btn btn-soft-dark" @click="restarBolsas(1)">
                        <i class="fa fa-minus"></i>
                      </button>
                    </div>
                  </td>
                  <td class="text-center">
                    {{formatoMoneda(subtotal_una_hora)}} USD
                  </td>
                </tr>
                <tr>
                  <td>
                    5 holas
                  </td>
                  <td>
                    <div class="input-group">
                      <button class="btn btn-soft-dark" @click="sumarBolsas(2)">
                        <i class="fa fa-plus"></i>
                      </button>
                      <input class="form-control text-center" readonly v-model="cantidad_cinco_horas">
                      <button class="btn btn-soft-dark" @click="restarBolsas(2)">
                        <i class="fa fa-minus"></i>
                      </button>
                    </div>
                  </td>
                  <td class="text-center">
                    {{formatoMoneda(subtotal_cinco_horas)}} USD
                  </td>
                </tr>
                <tr>
                  <td>
                    10 hola
                  </td>
                  <td>
                    <div class="input-group">
                      <button class="btn btn-soft-dark" @click="sumarBolsas(3)">
                        <i class="fa fa-plus"></i>
                      </button>
                      <input class="form-control text-center" readonly v-model="cantidad_dies_horas">
                      <button class="btn btn-soft-dark" @click="restarBolsas(3)">
                        <i class="fa fa-minus"></i>
                      </button>
                    </div>
                  </td>
                  <td class="text-center">
                    {{formatoMoneda(subtotal_dies_horas)}} USD
                  </td>
                </tr>
                <tr>
                  <td>
                    20 hola
                  </td>
                  <td>
                    <div class="input-group">
                      <button class="btn btn-soft-dark" @click="sumarBolsas(4)">
                        <i class="fa fa-plus"></i>
                      </button>
                      <input class="form-control text-center" readonly v-model="cantidad_veinte_horas">
                      <button class="btn btn-soft-dark" @click="restarBolsas(4)">
                        <i class="fa fa-minus"></i>
                      </button>
                    </div>
                  </td>
                  <td class="text-center">
                    {{formatoMoneda(subtotal_veinte_horas)}} USD
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="p-4 m-4 text-center bg-warning rounded" v-show="plan_seleccionado == 1">
              <span>
                <i class="fa fa-exclamation-circle fa-4x text-white"></i>
              </span>
              <br>
              <span class="h4 text-white">
                <strong>El soporte basico no genera ningun cargo</strong>
              </span>
            </div>

            <hr style="margin-top: 1rem; margin-bottom: 1rem;">

            <div class="p-3 m-1 rounded text-center bg-soft-dark">
              <span class="h4 text-white">
                <strong v-show="plan_seleccionado == 2">Total a pagar: {{formatoMoneda(total_pagar)}} USD</strong>
                <strong v-show="plan_seleccionado == 1">Las funciones del modulo estan activas</strong>
              </span>
            </div>
            <br><br>

            <div class="text-center">
              <div class="p-4 m-4 rounded text-center bg-success b-r-xl" v-show="plan_seleccionado == 1">
                <span class="h4 text-white">
                  Modulo activo
                </span>
              </div>
              <div ref="paypal" v-show="plan_seleccionado == 2"></div>
            </div>
          </div>
        </div>
        <div class="text-right" style="min-width: 220px;">
          <button class="btn btn-secondary" @click="$router.go(-1)">
            <i class="mdi mdi-chevron-left"></i>
            Atras
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">Registro de soportes</h4>
          </div>
          <div class="card-body" style="min-height: 475px;">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Tipo</th>
                    <th>Inicio</th>
                    <th>Fin</th>
                    <th>Descripción</th>
                    <th>Horas</th>
                    <th>Estado</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="soporte in soportes" :key="soporte.id">
                    <td>{{ soporte.id }}</td>
                    <td>{{ soporte.tipo }}</td>
                    <td>{{ soporte.inicio }}</td>
                    <td>{{ soporte.fin }}</td>
                    <td>{{ soporte.descripcion }}</td>
                    <td>{{ soporte.horas }}</td>
                    <td class="text-center">
                      <span 
                        class="badge bg-gradient font-size-12"
                        :class="{
                          'bg-success':  soporte.estado == 'Finalizado',
                          'bg-info':     soporte.estado == 'Procesos',
                          'bg-soft-dark':   soporte.estado == 'Por iniciar'
                        }"
                      >
                        {{soporte.estado}}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import accounting from 'accounting/accounting.js'
export default {
  name: 'Adm3ArgusblackModulosSoportes',
  components: { Layout },
  data() {
    return {
      plan_seleccionado: 1,      // 1 = soporte basico, 2 = soporte avanzado
      modulo: true,
      client_id_paypal: 'AezTOJk4MD-cfZsKQP7CUic23cEGkVuvLQvd0BXi1lOMIBtw46DwHhskYtK_gfbmehCW1wOiYpLB0r4c',
      currency_paypal: 'USD',

      cantidad_una_hora: 0,
      cantidad_cinco_horas: 0,
      cantidad_dies_horas: 0,
      cantidad_veinte_horas: 0,

      subtotal_una_hora: 0,
      subtotal_cinco_horas: 0,
      subtotal_dies_horas: 0,
      subtotal_veinte_horas: 0,

      total_pagar: 0,

      soportes: [
        {
          id: 1,
          tipo: "Basico",
          inicio: "2022-06-20 10:00:00",
          fin: "2022-06-20 11:00:00",
          descripcion: "Se realizaron accesos remotos a su red por medio de VPN",
          horas: 1,
          estado: "Finalizado"
        },
        {
          id: 2,
          tipo: "Basico",
          inicio: "2022-06-20 12:00:00",
          fin: "2022-06-20 13:00:00",
          descripcion: "Se realizaron accesos remotos a su red por medio de VPN",
          horas: 1,
          estado: "Finalizado"
        },
        {
          id: 3,
          tipo: "Basico",
          inicio: "2022-06-20 12:00:00",
          fin: "2022-06-20 13:00:00",
          descripcion: "Se realizaron accesos remotos a su red por medio de VPN",
          horas: 1,
          estado: "Procesos"
        },
        {
          id: 4,
          tipo: "Basico",
          inicio: "2022-06-20 12:00:00",
          fin: "2022-06-20 13:00:00",
          descripcion: "Se realizaron accesos remotos a su red por medio de VPN",
          horas: 1,
          estado: "Por iniciar"
        }
      ]
    }
  },

  watch: {
    cantidad_una_hora: function(oldt,newt){
      var self = this

      self.subtotal_una_hora = oldt * 20

      if(oldt < 0) self.subtotal_una_hora = 0

      self.sumaTotal()
    },
    cantidad_cinco_horas: function(oldt,newt){
      var self = this

      self.subtotal_cinco_horas = oldt * 90

      if(oldt < 0) self.subtotal_cinco_horas = 0

      self.sumaTotal()
    },
    cantidad_dies_horas: function(oldt,newt){
      var self = this

      self.subtotal_dies_horas = oldt * 180

      if(oldt < 0) self.subtotal_dies_horas = 0

      self.sumaTotal()
    },
    cantidad_veinte_horas: function(oldt,newt){
      var self = this

      self.subtotal_veinte_horas = oldt * 340

      if(oldt < 0) self.subtotal_veinte_horas = 0

      self.sumaTotal()
    }
  },
  created: function () {
    var self = this

    const script = document.createElement('script')
    script.src = `https://www.paypal.com/sdk/js?currency=${self.currency_paypal}&client-id=${self.client_id_paypal}&disable-funding=card`
    // script.src = `https://www.paypal.com/sdk/js?currency=${self.currency_paypal}&client-id=${self.client_id_paypal}`
    script.addEventListener('load', self.setLoaded)
    document.body.appendChild(script)

    // $(document).ready(function() {
    //   $('#moduloSoporte').bootstrapToggle(self.modulo ? 'on' : 'off')

    //   $('#moduloSoporte').on('change', function(event) {
    //     if (self.modulo) self.modulo = 0
    //     else self.modulo = 1
    //   })
    // })
  },
  methods: {
    cadenaPago() {
      return 'app_sgcm-cln_15154-15154'
    },

    formatoMoneda: function (numero) {
      return accounting.formatMoney(numero)
    },

    seleccionarPlan(plan){
      var self = this

      self.plan_seleccionado = plan
    },

    setLoaded: function () {
      var self = this

      self.loaded = true

      var descripcion = "Cambio de plan emprendedor a negocio"

      window.paypal.Buttons({
        style: {
          size: 'small',
          color: 'blue',
          shape: 'pill',
          label: 'pay',
          height: 40,
        },createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: descripcion,
                amount: {
                  value: self.total_pagar,
                },
                custom_id: self.cadenaPago(),
              },
            ],
          })
        },onApprove: async (data, actions) => {
          const order = await actions.order.capture()
          self.paidFor = true
          console.log(order)
          swal("Aprobado!", "El cambio al Plan Negocio ha sido exitosa.", "success");
        },onError: (err) => {
          console.log(err)
        },
      }).render(self.$refs.paypal)
    },

    sumarBolsas(tipo) {
      var self = this

      switch(tipo){
        case 1: 
          self.cantidad_una_hora += 1
        break;
        case 2: 
          self.cantidad_cinco_horas += 1
        break;
        case 3: 
          self.cantidad_dies_horas += 1
        break;
        case 4: 
          self.cantidad_veinte_horas += 1
        break;
      }
    },

    sumaTotal(){
      var self = this

      self.total_pagar = self.subtotal_una_hora + self.subtotal_cinco_horas + self.subtotal_dies_horas + self.subtotal_veinte_horas
    },

    restarBolsas(tipo) {
      var self = this

      switch(tipo){
        case 1: 
          if(self.cantidad_una_hora <= 0) return
          self.cantidad_una_hora -= 1
        break;
        case 2: 
          if(self.cantidad_cinco_horas <= 0) return
          self.cantidad_cinco_horas -= 1
        break;
        case 3: 
          if(self.cantidad_dies_horas <= 0) return
          self.cantidad_dies_horas -= 1
        break;
        case 4: 
          if(self.cantidad_veinte_horas <= 0) return
          self.cantidad_veinte_horas -= 1
        break;
      }
    },
  }
}
</script>

<style scoped>

</style>